@font-face {
  font-family: 'GillSansNova';
  src: url(./fonts/GillSansNova/GillSansNova-Book.eot);
  src: url(./fonts/GillSansNova/GillSansNova-Book.eot?#iefix) format("embedded-opentype"), url(./fonts/GillSansNova/GillSansNova-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'GillSansNova';
  src: url(./fonts/GillSansNova/GillSansNova-Medium.eot);
  src: url(./fonts/GillSansNova/GillSansNova-Medium.eot?#iefix) format("embedded-opentype"), url(./fonts/GillSansNova/GillSansNova-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'GillSansNova';
  src: url(./fonts/GillSansNova/GillSansNova-SemiBold.eot);
  src: url(./fonts/GillSansNova/GillSansNova-SemiBold.eot?#iefix) format("embedded-opentype"), url(./fonts/GillSansNova/GillSansNova-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: 'Madonna';
  src: url(./fonts/Madonna/Madonna_22-3.eot);
  src: url(./fonts/Madonna/Madonna_22-3.eot?#iefix) format("embedded-opentype"), url(./fonts/Madonna/Madonna_22-3.woff) format("woff");
  font-weight: 400;
  font-style: normal
}
